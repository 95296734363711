@import "src/shared/styles/colors.scss";

.qr-code-canva {
	display: none;

    .logo-section {
        background: linear-gradient(90deg, #D1D0FF 0%, #FFDCFC 100%);
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem ;

        .julaya-logo {
            width: 150px; 
            object-fit: contain;
        }
    }

    .qr-code-title-section {
        width: 100%;
        padding: 1rem; 
    }

    .qr-code-section {
        width: 100%;
		height: 70%;
        min-height: 250px; 
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $neutral-200;
        border-bottom: 1px solid $neutral-200;

        .qr-code {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: auto;
            max-width: 200px; 
            max-height: 200px;
        }
    }
}

@media print {
    .qr-code-canva {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 92vh;
        width: 100%;
        padding: 2rem;
    }
}
